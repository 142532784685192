<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:0 20px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="状态">
                                                    <a-radio-group default-value="PublishStatus" v-model="PublishStatus" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="0">未发布</a-radio-button>
                                                        <a-radio-button value="1">已发布</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="类型">
                                                    <a-radio-group default-value="MaterialType" v-model="MaterialType" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="0">朋友圈</a-radio-button>
                                                        <a-radio-button value="1">推广文章</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="素材">
                                                    <a-input placeholder="输入素材标题" v-model="Name" :value="Name" style="width:278px;" />
                                                </a-form-item>
                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                                <a-col :span="4" class="textal_r">
                                    <a-button type="primary" @click="Store()" style="margin-right:10px;">素材市场</a-button>
                                    <a-button type="primary" @click="AddRow(0)">创建素材</a-button>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table bordered :columns="columns" rowKey="ID" :data-source="Material" :pagination="pagination" @change="pagerChange">
                            <span slot="action" slot-scope="text, record">
                                <a-button type="link" size="small" @click="UpdateState(record)">{{ record.Material.PublishStatus == 0 ? '发布' : '撤回' }}</a-button>
                                <a-divider type="vertical"></a-divider>
                                <a-button type="link" size="small" @click="EditRow(record)">{{ record.Material.PublishStatus == 0 ? '编辑' : '详情' }}</a-button>
                                <a-divider v-if="record.StoreState == 1" type="vertical"></a-divider>
                                <a-button v-if="record.StoreState == 1" type="link" size="small" @click="DelRow(record)">删除</a-button>
                                <!--<a-divider type="vertical"></a-divider>
                                <a-button type="link" size="small" @click="ShowList(record)">明细</a-button>-->
                            </span>
                            <span slot="ReadCount" slot-scope="text, record">
                                <a @click="ReadCount(record)" style="cursor:pointer;">{{record.ReadCount }}</a>
                            </span>
                            <span slot="PullNewCount" slot-scope="text, record">
                                <a @click="PullNewCount(record)" style="cursor:pointer;">{{record.PullNewCount }}</a>
                            </span>
                        </a-table>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "NewMaterial_List",
        data() {
            return {
                Material: [],
                MaterialList: [],
                columns: [
                    {
                        title: "素材图片",
                        width: 1,
                        align: "center",
                        customRender: (text, item) => {
                            var img = item.Material.Content.ImgTopic;
                            var logo = item.Material.Logo;
                            if (item.Material.MaterialType == 0) {
                                return <div><img src={img} width="60" height="60" /></div>
                            }
                            if (item.Material.MaterialType == 1) {
                                return <div><img src={logo} width="60" height="60" /></div>
                            }
                        }
                    },
                    {
                        title: "素材标题",
                        dataIndex: "Material.Name",
                        width: 100
                    },
                    {
                        title: "创建时间",
                        align: "center",
                        width: 110,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.Material.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "素材类型",
                        width: 1,
                        align: "center",
                        customRender: (text, item) => {
                            var Status = item.Material.MaterialType;
                            if (Status == "0") {
                                return <div>朋友圈</div>
                            } else {
                                return <div>软文推广</div>
                            }
                        }
                    },
                    {
                        title: "发布状态",
                        width: 1,
                        align: "center",
                        customRender: (text, item) => {
                            var Status = item.Material.PublishStatus;
                            if (Status == "0") {
                                return <div>未发布</div>
                            } else {
                                return <div>已发布</div>
                            }
                        }
                    },
                    {
                        title: "发布时间",
                        align: "center",
                        width: 110,
                        customRender: (text, item) => {
                            var Time = "未发布";
                            if (item.Material.PublishStatus == 1) {
                                Time = util.TimeStamptoDateTime(item.Material.PublishTime, 'yyyy-MM-dd hh:mm:ss')
                            }
                            return Time
                        }
                    },
                    {
                        title: "阅读量",
                        width: 1,
                        align: "center",
                        scopedSlots: { customRender: 'ReadCount' }
                    },
                    {
                        title: "拉新数量",
                        width: 1,
                        align: "center",
                        scopedSlots: { customRender: 'PullNewCount' }
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                        width: 130
                    }
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PublishStatus: "-1",
                MaterialType: "-1",
                Name: ""
            };
        },
        methods: {
            //查询事件
            QueryData() {
                var self = this;
                self.pagination.current = 1;
                this.loadData();
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialCustomerList",
                    data: {
                        PublishStatus: self.PublishStatus,
                        MaterialType: self.MaterialType,
                        Name: self.Name,
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.Material = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            AddRow(e) {
                var id = 0;
                if (e != '0') {
                    id = e.ID;
                }
                this.$router.push({
                    name: "NewMaterial_Add",
                    query: { id: id, QueueID: 0, disabled: 0 }
                });
            },
            Store() {
                this.$router.push({ name: "Store_List" });
            },
            EditRow(e) {
                var id = e.MaterialID;
                var type = e.Material.MaterialType;
                if (type == 0) {
                    this.$router.push({
                        name: "NewMaterial_MomentDetail",
                        query: { id: id, disabled: e.Material.PublishStatus, QueueID: 0 }
                    });
                }
                if (type == 1) {
                    this.$router.push({
                        name: "NewMaterial_ExtensionDetail",
                        query: { id: id, disabled: e.Material.PublishStatus, QueueID: 0 }
                    });
                }
            },
            DelRow(e) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + e.Titles,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Material/DeleteMaterial",
                            data: {
                                id: e.ID
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            UpdateState(e) {
                var self = this;
                var con = e.Material.PublishStatus == 0 ? '发布' : '撤回';
                var Status = e.Material.PublishStatus == 0 ? 1 : 0;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Material.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/Material/UpdateStatus",
                            data: {
                                ID: e.MaterialID,
                                PublishStatus: Status
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                                self.$message.success(con + "成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            ShowList(e) {
                var id = e.MaterialID;
                this.$router.push({
                    name: "Material_ReadList",
                    query: { id: id }
                });
            },
            ReadCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        id: data.ID,
                        IsNewUser: 0
                    }
                })
            },
            PullNewCount: function (data) {
                var self = this;
                self.$router.push({
                    name: "Material_ReadList", query: {
                        id: data.ID,
                        IsNewUser: 1
                    }
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>



